import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import jquery from "../../node_modules/jquery/src/jquery.js";
import jqueryUI from "../../node_modules/jquery-ui/ui/core.js";
import jqueryUIEffect from "../../node_modules/jquery-ui/ui/effect.js";

UIkit.use(Icons);

/**
 * Rende disponibili le librerie nello scope globale.
 * Alternativamente, spostare gli import in app.js
 */
window.UIkit = UIkit;
window.$ = jquery;
